import '../css/Webdev.css';

const Home = () => {
    return (
        <div className="Webdev">
            <h1 className="WIP">
                WIP
                <br/>
            </h1>
            <h2 className="WIP2">
                Come back later!
            </h2>
        </div>
    );
};

export default Home;