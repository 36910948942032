import '../css/Assassins.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios'; 

// https://docs.google.com/spreadsheets/d/e/2PACX-1vQZtST8tC9De_3SnF4bUBInOU5spz3BgZZPL7rvKNZjlRSweXBEwg_eGOH6k-OLSPtwdQcp9-O4C5md/pub?gid=0&single=true&output=csv

const Assassins = () => {

    const [inputValue, setInputValue] = useState('');
    const [csvData, setCsvData] = useState({});
    const [result, setResult] = useState('');

    useEffect(() => {
        fetchSheetData();    // Fetch the CSV data when the component mounts
    }, []); // The empty array ensures that this effect runs only once, like componentDidMount

    const fetchSheetData = async () => {
        const spreadsheetId = '14pG7Lmg4MLMv1bymBEBliEn63po22iUVcIi-Ls4v1C8'; // Replace with your spreadsheet ID
        const range = 'Sheet1!A:B'; // Adjust the sheet name and range as needed
        const apiKey = 'AIzaSyAq6303wWP30DBM8mLNTITsw5Eo8ibDrg4 '; // Replace with your API key
    
        const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?key=${apiKey}`;
    
        try {
          const response = await axios.get(url);
          const parsedData = parseSheetData(response.data.values);
          setCsvData(parsedData);
        } catch (error) {
          console.error('Error fetching sheet data:', error);
        }
      };

    const parseSheetData = (values) => {
    const data = {};
    for (let i = 1; i < values.length; i++) {
        const [key, value] = values[i];
        if (key && value) {
        data[key.trim()] = value.trim();
        }
    }
    return data;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchSheetData();
        const key = inputValue.trim();
        if (csvData[key]) {
            setResult(`YOUR TARGET IS: ${csvData[key]}`);
        } else {
            setResult('Could not find your student number.');
        }
    };

    return (
        <div className="Assassin">
            <div className="ass-title">HSS ASSASSIN HANDLER</div>
            <form className="ass-field" onSubmit={handleSubmit}>
                <label>
                    Enter Student Number:
                    <input
                        type="number"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                    />
                </label>
                <button type="submit" style={{ marginLeft: '10px' }}>
                    SUBMIT
                </button>
            </form>
            {result && <p className="result">{result}</p>}
        </div>
    )
}

export default Assassins;